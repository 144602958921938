import React from "react";
import moduleStyle from "./WhitelabelLock.module.css";
import LockIcon from "@material-ui/icons/Lock";
import ExlyModal from "common/Components/ExlyModal";
import { is_empty } from "utils/validations";
import { WhitelabelModalDescription } from "./modules/WhitelabelModalDescription";
import { useWhitelabel } from "../../utils/useWhitelabel";
import { useWhitelabelLock } from "./useWhitelabelLock";
import { ReactComponent as AutoFixHigh } from "assets/vectors/autoFixHigh.svg";
import { RUPEE_SYMBOL } from "features/Common/constants/currency.constants";

export const WhitelabelLock = ({
  label = "Locked",
  hideLabel = false,
  modalTitle = "Pay & Unlock",
  modalCTA = "Purchase now",
  modalDescription,
  className = "",
  iconStyle = { fill: "rgba(255, 170, 0, 1)", fontSize: 18 },
}) => {
  const { isAccessible, handleWhitelabelPurchase, whiteLabelDetails } =
    useWhitelabel();
  const { showWhitelabelUnlockModal, toggleWhitelabelUnlockModal } =
    useWhitelabelLock();

  const ctaText = React.useMemo(() => {
    if (!is_empty(whiteLabelDetails)) {
      return (
        <div
          className={`d-flex align-items-center px-4 py-2 ${moduleStyle.ctaText}`}
        >
          <AutoFixHigh className={`${moduleStyle.auto_high} mr-1`} />
          Pay{"\u00A0"}
          {RUPEE_SYMBOL}
          <b>{whiteLabelDetails.price}</b>&nbsp;&nbsp;
          {!is_empty(whiteLabelDetails.og_price) ? (
            <>
              <div className={`strikethrough`}>
                {RUPEE_SYMBOL}
                {whiteLabelDetails.og_price}
              </div>
              &nbsp;&nbsp;
            </>
          ) : null}
          <div className={`small`}>(valid for 12 months)</div>
        </div>
      );
    } else return modalCTA;
  }, [whiteLabelDetails]);

  if (isAccessible) return null;
  return (
    <>
      <div
        id="WhitelabelLock"
        onClick={toggleWhitelabelUnlockModal}
        className={`${moduleStyle.chip} d-flex align-items-center fit_content pointer ${className}`}
      >
        <LockIcon style={{ ...iconStyle }} />
        {!hideLabel && (
          <div className={`ml-2 ${moduleStyle.label}`}>{label}</div>
        )}
      </div>

      {showWhitelabelUnlockModal && !is_empty(modalDescription) ? (
        <ExlyModal
          open={showWhitelabelUnlockModal}
          showSecondaryBtn={false}
          onClose={toggleWhitelabelUnlockModal}
          primaryBtnFullWidth
          primaryBtnText={ctaText}
          modal_props={{
            modalPaperClassName: moduleStyle.desktopModalPaper,
          }}
          onPrimaryBtnClick={(e) => {
            handleWhitelabelPurchase();
            toggleWhitelabelUnlockModal(e);
          }}
          title={modalTitle}
        >
          <WhitelabelModalDescription modalDescription={modalDescription} />
        </ExlyModal>
      ) : null}
    </>
  );
};
