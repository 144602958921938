import React from "react";
import { formatNumbeWithCommasString } from "@my-scoot/component-library-legacy";
import styles from "./SelectionCardStyles.module.css";
import classnames from "classnames";
import { pluralise } from "utils/Utils";
import BulkActionButton from "../BulkActionButton/BulkActionButton";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { getSelectionText } from "./SelectionCard.utils";
import { DEFAULT_PER_PAGE } from "constants/numbers";

const SelectionCard = ({
  selectedRecords,
  entityName,
  totalRecords,
  actions,
  currentPageFullSelection,
  selectAll,
  handleSelectAll,
}) => {
  const selectedRecordsLength = selectedRecords?.length || 0;
  const isDesktop = useDesktopMediaQuery();

  if (!selectedRecordsLength && !selectAll) return null;

  const showSelectAll =
    totalRecords > DEFAULT_PER_PAGE && currentPageFullSelection && !selectAll;
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.textWrapper}>
        <span className={styles.selectionTextStyle}>
          {getSelectionText({
            selectAll,
            selectedRecords,
            entityName,
            totalRecords,
            currentPageFullSelection,
          })}
        </span>
        <span
          className={classnames(styles.selectionTextStyle, styles.linkStyle)}
          onClick={() => handleSelectAll(showSelectAll)}
        >
          {showSelectAll
            ? `Select ${formatNumbeWithCommasString(totalRecords)} ${pluralise(
                entityName,
                totalRecords
              )} across all pages`
            : `Clear selection`}
        </span>
      </div>
      {isDesktop && (
        <div className={styles.buttonWrapper}>
          {actions.map(
            (actionItem) =>
              !actionItem?.hide && (
                <BulkActionButton actionItem={actionItem} key={actionItem} />
              )
          )}
        </div>
      )}
    </div>
  );
};

export default SelectionCard;
