export const BULK_ACTION_TABLES = {
  TRANSACTIONS: 1,
  NON_SUBSCRIPTION_BOOKINGS: 2,
  LEADS: 3,
  CUSTOMERS: 4,
  MY_BOOKINGS: 5,
  SUBSCRIPTION_BOOKINGS: 6,
};

export const BULK_ACTION_TYPES = {
  ASSIGN_TAGS: 1,
  BROADCAST: 2,
  REFUNDS: 3, // @DEV this is not supported or implemented now,will be needed later
};

export const requestIdParam = "request_id";
