import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { isRequestSuccessful, pluralise } from "utils/Utils";
import { BULK_ACTIONS_API_END_POINT } from "../constants/API";
import { BULK_ACTION_TABLES } from "../BulkActions.constants";
import { is_empty } from "utils/validations";
import { ENTITY_TYPE } from "constants/crmConstants";
import { BOOKING_LABEL } from "features/Crm/modules/Bookings/Bookings.constant";

/**
 * Initiates a bulk action by sending a request to the specified endpoint.
 *
 * @param {string}  bulkActionTable - The table on which the bulk action is to be performed.
 * @param {string}  actionType - The type of action to be performed.
 * @param {Array<number>} [ selectedIds] - The IDs of the selected items. Only sent if `selectAll` is false.
 * @param {Object} [ actionData] - Additional data for the action.(currently used to send tag_uuid in tag assignment)
 * @param {Object} [ filterData] - The filter data to be used.
 * @param {boolean}  selectAll - Flag indicating whether all items are selected.
 *
 * @returns {Promise<Object>} - A promise that resolves with the response data and status.
 *
 * @throws {Error} - Throws an error if the request was not successful.
 */
export const initiateBulkAction = async ({
  bulkActionTable,
  actionType,
  selectedIds,
  actionData,
  filterData,
  selectAll,
}) => {
  const queryParams = {
    bulk_action_table: bulkActionTable,
    action_type: actionType,
    select_all: selectAll,
    ...(selectedIds && !selectAll && { selected_ids: selectedIds }), // selectedIds to be sent only when selectAll is false
    ...(!is_empty(filterData) && { filter_data: filterData }),
    ...(actionData && { action_data: actionData }),
  };

  const { data, status } = await dataProvider.custom_request(
    BULK_ACTIONS_API_END_POINT.INITIATE_BULK_ACTION,
    apiMethods.POST,
    queryParams
  );
  if (!isRequestSuccessful(status))
    throw new Error(`Error initiating bulk action`);
  return { data, status };
};

/**
 * Performs a bulk action based on the provided request ID.
 *
 * @param {string}  requestId - The ID of the bulk action request.
 *
 * @returns {Promise<Object>} - A promise that resolves with the response data, status, and message.
 *
 * @throws {Error} - Throws an error if the request was not successful.
 */
export const performBulkAction = async ({ requestId }) => {
  if (!requestId) return;

  const { data, status, message } = await dataProvider.custom_request(
    BULK_ACTIONS_API_END_POINT.PERFORM_BULK_ACTION,
    apiMethods.POST,
    {
      request_id: requestId,
    }
  );
  if (!isRequestSuccessful(status))
    throw new Error(`Error performing bulk action`);
  return { data, status, message };
};

/**
 * Generates a label for the selected bulk action based on the table type and count.
 *
 * @param {string} table - The enum of table for which the bulk action is performed.
 * @param {number} count - The number of selected items.
 *
 * @returns {string} - The generated label for the selected bulk action using table & count.
 */
export const generateSelectedBulkActionLabel = ({ table, count }) => {
  if (table === BULK_ACTION_TABLES.CUSTOMERS)
    return `${pluralise(ENTITY_TYPE.customer.key, count)}`;
  else if (table === BULK_ACTION_TABLES.LEADS)
    return `${pluralise(ENTITY_TYPE.lead.key, count)}`;
  else return `${pluralise(BOOKING_LABEL, count)}`;
};
