import React from "react";
import { Button } from "@my-scoot/component-library-legacy";
import styles from "./BulkActionButtonStyles.module.css";
import classnames from "classnames";

const BulkActionButton = ({
  actionItem,
  isFullLayout,
  isSmall = true,
  className,
}) => {
  return (
    <Button
      key={actionItem}
      onClick={actionItem.onClick}
      classes={{ label: styles.btnLabelStyle }}
      className={classnames(
        isSmall && styles.btnStyle,
        isFullLayout && styles.btnWrapperFull,
        className
      )}
      buttonWrapperClassName={isFullLayout && styles.btnWrapperFull}
    >
      {actionItem.icon}
      {actionItem.text}
    </Button>
  );
};

export default BulkActionButton;
