import { getCreatorHostsiteURL } from "features/Account/utils/getCreatorPageDetails";

export const getCheckoutPageShareUrl = (listing) => {
  const { url_slug, uuid } = listing;
  const url = `${getCreatorHostsiteURL().url}/checkout`;

  if (url_slug) {
    return `${url}/${url_slug}`;
  }

  return `${url}/${uuid}`;
};
