import React from "react";
import { EmojiObjects } from "@material-ui/icons";

export const ADS_MAX_HEADLINE_LENGTH = 255;
export const ADS_MAX_DESCRIPTION_LENGTH = 255;
export const CTA_OPTIONS = [
  {
    label: `Learn${"\u00A0"}More`,
    value: 2,
    id: 2,
    icon: (
      <>
        {" "}
        <EmojiObjects />
        <b>Suggested</b>
      </>
    ),
  },
  { label: `Book${"\u00A0"}Now`, value: 1, id: 1 },
  {
    label: `Sign${"\u00A0"}up`,
    value: 3,
    id: 3,
  },
];
