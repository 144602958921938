import {
  EXLY_ACCOUNT__SET_CART,
  EXLY_ACCOUNT__SET_CREATOR_ACTIVE_PLAN,
  EXLY_ACCOUNT__SET_FEATURE_CREDITS,
  EXLY_ACCOUNT__SET_LEDGER_TRIGGERS,
} from "ui/pages/Account/redux/actions";
import { is_empty } from "utils/validations";
import {
  EXLY_ACCOUNT__SET_FEATURE_ACCESSIBILITY,
  EXLY_ACCOUNT__SET_FEATURE_DETAILS,
  EXLY_ACCOUNT__SET_HIGHLIGHTED_FEATURE,
  EXLY_ACCOUNT__SET_SHOW_WHITELABEL_UNLOCK_MODAL,
} from "./Account.actions";

const initialState = {
  featureCredits: {},
  creatorPlan: {},
  highlightedFeature: {},
  cart: {},
  ledgerTriggers: {},
  featureAccessibilty: {},
  showWhitelabelUnlockModal: false,
  featureDetails: {},
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EXLY_ACCOUNT__SET_FEATURE_CREDITS: {
      if (
        is_empty(payload) ||
        is_empty(payload?.data) ||
        is_empty(payload?.feature_key)
      ) {
        return state;
      }
      return {
        ...state,
        featureCredits: {
          ...state.featureCredits,
          [payload.feature_key]: Object.assign(
            { ...state.featureCredits[payload.feature_key] },
            payload.data
          ),
        },
      };
    }

    case EXLY_ACCOUNT__SET_CART: {
      return {
        ...state,
        cart: payload,
      };
    }

    case EXLY_ACCOUNT__SET_CREATOR_ACTIVE_PLAN: {
      if (is_empty(payload)) return state;
      return {
        ...state,
        creatorPlan: payload,
      };
    }

    case EXLY_ACCOUNT__SET_SHOW_WHITELABEL_UNLOCK_MODAL: {
      return {
        ...state,
        showWhitelabelUnlockModal: payload,
      };
    }

    case EXLY_ACCOUNT__SET_FEATURE_DETAILS: {
      if (is_empty(payload?.featureKey) || is_empty(payload.data)) {
        return state;
      }
      return {
        ...state,
        featureDetails: {
          ...state.featureDetails,
          [payload?.featureKey]: Object.assign(
            state.featureDetails?.[payload?.featureKey] ?? {},
            payload.data
          ),
        },
      };
    }
    case EXLY_ACCOUNT__SET_HIGHLIGHTED_FEATURE: {
      if (is_empty(payload)) return state;
      return {
        ...state,
        highlights: payload,
      };
    }

    case EXLY_ACCOUNT__SET_LEDGER_TRIGGERS: {
      if (is_empty(payload)) return state;
      return {
        ...state,
        ledgerTriggers: {
          ...state.ledgerTriggers,
          [payload.feature_key]: payload.data,
        },
      };
    }

    case EXLY_ACCOUNT__SET_FEATURE_ACCESSIBILITY: {
      if (!is_empty(payload?.featureKey)) {
        return {
          ...state,
          featureAccessibilty: {
            ...state.featureAccessibilty,
            [payload.featureKey]: payload.accessibility,
          },
        };
      }
      return state;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
